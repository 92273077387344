<template>
  <a-spin :spinning="loading" :tip="loadingTip">
    <div class="tableList">
      <div class="search">
        <div class="search-body">
          <a-form-model layout="inline">
            <a-form-model-item label="申请人">
              <a-input v-model="search.postUserName" placeholder="请输入姓名" />
            </a-form-model-item>
            <a-form-model-item label="项目名称">
              <a-input v-model="search.xiangmu" placeholder="请输入项目名称" />
            </a-form-model-item>
            <a-form-model-item label="状态">
              <a-select v-model="search.status" placeholder="请选择" allowClear style="width: 120px">
                <a-select-option :value="0">待提交</a-select-option>
                <a-select-option :value="1">已提交</a-select-option>
                <a-select-option :value="2">通过</a-select-option>
                <a-select-option :value="3">不通过</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="日期">
              <a-range-picker @change="onTimeChange" />
            </a-form-model-item>
            <a-form-model-item>
              <p-button type="primary" name="搜索" @click="searchList" />
            </a-form-model-item>
            <a-form-model-item style="float: right">
              <a-button type="primary" style="margin-right: 10px" @click="addOrUpdate(null)">新增</a-button>
              <a-button type="default" @click="exportData">导出</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <div class="tableItem">
        <a-table rowKey="id" :columns="tableInfo.columns" :data-source="tableInfo.datas"
          :pagination="tableInfo.pagination" @change="onChange" :locale="{ emptyText: '暂无数据' }"
          :scroll="{ y: tableInfo.tableHeight }">
          <template slot="status" slot-scope="info">
            <processStatus :processModel="info" />
          </template>
          <span slot="action" slot-scope="action">
            <template v-if="action.creator == currUser">
              <a-button v-if="action.status == 0 || action.status == 3" @click="addOrUpdate(action)" size="small"
                type="primary">修改</a-button>
              <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="changeStatus(action, 1)"
                v-if="action.status == 0 || action.status == 3">
                <a-button size="small" type="danger">提交</a-button>
              </a-popconfirm>
              <a-popconfirm v-if="action.status == 0 || action.status == 3" title="确定要删除吗？"
                @confirm="deleteItem(action.id)">
                <a-button size="small" type="danger">删除</a-button>
              </a-popconfirm>
            </template>
            <a-button @click="addOrUpdate(action)" size="small" type="primary"
              v-if="action.processExecuteStatus">审批</a-button>
            <a-button @click="addOrUpdate(action, true)" size="small" type="default"
              v-if="action.status == 1 || action.status == 2">详情</a-button>
          </span>
        </a-table>
      </div>
      <a-modal :title="editor.title" v-model="editor.visible" width="90%" :footer="false" destroyOnClose
        :maskClosable="false">
        <incomebill :info="editor.info" :modal="true" @callback="modelCallBack" />
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import incomebillApi from "@/api/work/incomebill";
import incomebill from "./incomebill.vue";

var _this;
const columns = [
  {
    key: "index",
    align: "center",
    title: "序号",
    customRender: (text, record, index) => {
      return (
        (_this.tableInfo.pagination.current - 1) *
        _this.tableInfo.pagination.defaultPageSize +
        index +
        1
      );
    },
  },
  {
    dataIndex: "orgName",
    align: "center",
    title: "部门",
  },
  {
    dataIndex: "creatorName",
    align: "center",
    title: "经办人",
  },
  {
    dataIndex: "applyTime",
    align: "center",
    title: "日期",
  },
  {
    dataIndex: "price",
    align: "center",
    title: "金额(元)",
  },
  {
    dataIndex: "content",
    align: "center",
    title: "内容",
  },
  {
    key: "status",
    align: "center",
    title: "状态",
    scopedSlots: { customRender: "status" },
  },

  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "incomebilllist",
  data() {
    return {
      loading: false,
      loadingTip: "加载中",
      search: {},

      tableInfo: {
        datas: [],
        columns,
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: false,
          showSizeChanger: true,
          total: 0,
          current: 1,
          defaultCurrent: 1,
          defaultPageSize: 10,
          showTotal: (total) => `总共${total}条`,
        },
      },
      selectRows: [],
      editor: {
        title: "编辑",
        visible: false,
        info: {},
      },
      currUser: localStorage.getItem(this.$commons.User.userName),
    };
  },
  components: {
    incomebill,
  },
  mounted() {
    _this = this;
    this.getPageList();
  },
  methods: {
    changeStatus(data, status) {
      this.loading = true;
      incomebillApi.changeStatus(data.id, status).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    deleteItem(id) {
      this.loading = true;
      incomebillApi.deleteItem(id).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    async getPageList() {
      let _this = this;
      let param = {
        pageNow: _this.tableInfo.pagination.current,
        pageSize: _this.tableInfo.pagination.defaultPageSize,
        ..._this.search,
      };
      incomebillApi.getList(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.tableInfo.datas = res.data.records;
          _this.tableInfo.pagination.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    exportData() {
      var _this = this;
      _this.loading = true;
      incomebillApi.exportData({ ...this.search }, (d) => {
        _this.loadingTip = "已完成" + d + "%";
        if (d == 100) {
          setTimeout(function () {
            _this.loading = false;
          }, 2000);
        }
      });
    },
    onTimeChange(date, dateString) {
      this.search.startTime = dateString[0];
      this.search.endTime = dateString[1];
    },
    onChange(page) {
      let _this = this;
      _this.tableInfo.pagination.current = page.current;
      _this.getPageList();
    },
    searchList() {
      let _this = this;
      _this.getPageList();
    },

    addOrUpdate(info) {
      let _this = this;
      _this.editor.visible = true;
      if (info) {
        _this.editor.title = "编辑";
        if (info.status == 1 || info.status == 2) {
          _this.editor.title = "查看";
        }
        _this.editor.info = info;
      } else {
        _this.editor.title = "新增";
        _this.editor.info = null;
      }
    },

    modelCallBack() {
      this.editor.visible = false;
      this.getPageList();
    },
  },
};
</script>

<style lang="less"></style>