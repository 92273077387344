import request from "@/request/index";
const part_url = "/oa/incomeBill/";

export default {
    addOrUpdate(params) {
        let url = part_url + "addOrUpdate";
        return request.post(url, {}, params);
    },
    getList(params) {
        let url = part_url + "getList";
        return request.get(url, params, {});
    },
    getListForUser(params) {
        let url = part_url + "getListForUser";
        return request.get(url, params, {});
    },
    changeStatus(id, status) {
        let url = part_url + "changeStatus";
        return request.get(url, { id, status }, {});
    },
    getData(id) {
        let url = part_url + "getData";
        return request.get(url, { id }, {});
    },
    getLastForUser() {
        let url = part_url + "getLastForUser";
        return request.get(url, {}, {});
    },
    deleteItem(id) {
        let url = part_url + "deleteItem";
        return request.get(url, { id }, {});
    },
    exportData(params, onProgress, onError) {
        return request.downloadFile(part_url + 'exportData', params, '收入票据整理单.xls', onProgress, onError);
    },
    getUserRolesByName(userName) {
        let url = '/uas/admin/role/getUserRolesByName';
        return request.get(url, { userName }, {});
    }

}
